<template>
  <div id="modify_login_pwd">
    <el-form :model="infoForm" :rules="rules" ref="infoForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="真实姓名："  prop="actual_name">
        <div class="personal_info">
          {{infoForm.actual_name}}
        </div>
      </el-form-item>
      <el-form-item label="证件号码："  prop="ID_num">
        <div class="personal_info">
          {{infoForm.ID_num}}
        </div>
      </el-form-item>
      <el-form-item label="手机号："  prop="ph_num">
        <div class="personal_info">
          {{infoForm.ph_num}}
        </div>
      </el-form-item>

      <el-form-item label="支付宝账户："  prop="alipay">
        <el-input placeholder="请输入支付宝账户" v-model="infoForm.alipay" ></el-input>
      </el-form-item>
      <el-form-item label="确认账户："  prop="re_alipay">
        <el-input placeholder="请确认支付宝账户" v-model="infoForm.re_alipay" ></el-input>
      </el-form-item>
      <el-form-item  prop="alipayCode" class="alipayCode">
        <div class="alipayCodeLabel" slot="label">上传支付宝收款码：</div>
        <el-upload
          class="alipayCode-uploader"
          action="https://api.pxb7.com/api/upload/qrcode"
          :headers="{
              LoginStatus: this.$store.state.loginStatus,
              Authorization: this.$store.state.token,
            }"
          :show-file-list="false"
          :on-success="alipaySuccess"
          :before-upload="QRcodeUpload">
          <img v-if="alipayCode" :src="alipayCode" class="alipayCode_img">
          <img v-else-if="infoForm.alipayCode" :src="infoForm.alipayCode" class="wechatCode_img">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="微信号："  prop="wechat">
        <el-input placeholder="请输入微信号" v-model="infoForm.wechat" ></el-input>
      </el-form-item>
      <el-form-item label="确认微信号："  prop="re_wechat">
        <el-input placeholder="请确认微信号" v-model="infoForm.re_wechat" ></el-input>
      </el-form-item>
      <el-form-item  prop="wechatCode" class="wechatCode">
        <div class="wechatCodeLabel" slot="label">上传微信收款码：</div>
        <el-upload
          class="wechatCode-uploader"
          action="https://api.pxb7.com/api/upload/qrcode"
          :headers="{
              LoginStatus: this.$store.state.loginStatus,
              Authorization: this.$store.state.token,
           }"
          :show-file-list="false"
          :on-success="wechatSuccess"
          :before-upload="QRcodeUpload">
          <img v-if="wechatCode" :src="wechatCode" class="wechatCode_img">
          <img v-else-if="infoForm.wechatCode" :src="infoForm.wechatCode" class="wechatCode_img">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="银行卡账户："  prop="bank">
        <el-input placeholder="请输入银行卡账户" v-model="infoForm.bank" ></el-input>
      </el-form-item>
      <el-form-item label="确认账户："  prop="re_bank">
        <el-input placeholder="请确认银行卡账户" v-model="infoForm.re_bank" ></el-input>
      </el-form-item>
      <el-form-item label="开户行名称："  prop="bankName">
        <el-input placeholder="请输入开户行名称" v-model="infoForm.bankName" ></el-input>
      </el-form-item>
      <el-form-item label="收款人姓名："  prop="name">
        <el-input placeholder="请输入收款人姓名" v-model="infoForm.name" ></el-input>
      </el-form-item>
    </el-form>
    <div class="submit_btn" @click="submitForm('infoForm')">确认提交</div>
  </div>
</template>
<script>
import {apiBankName,apiUserSetAccount,apiuserInfo} from '@/request/API'
export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    var validateAlipay = (rule, value, callback) => {
      if (value !== this.infoForm.alipay) {
        callback(new Error('两次输入支付宝账户不一致!'))
      } else {
        callback()
      }
    }
    var validateWechat = (rule, value, callback) => {
      if (value !== this.infoForm.wechat) {
        callback(new Error('两次输入微信号不一致!'))
      } else {
        callback()
      }
    }
    var validateBank = (rule, value, callback) => {
      if (value !== this.infoForm.bank) {
        callback(new Error('两次输入银行卡账户不一致!'))
      } else {
        apiBankName({
          bankcard:value
        }).then(res=>{
          this.infoForm.bankName = res.data.bankName
        })
        callback()
      }
    }
    var valiName = (rule, value, callback) => {
      if (value !== this.infoForm.actual_name) {
        callback(new Error('请输入您的真实姓名!'))
      } else {
        callback()
      }
    }
    return {
      alipayCode:'',
      wechatCode:'',
      infoForm:{
        actual_name:'',
        ID_num:'',
        ph_num:this.$store.state.userData.telphone,
        alipay:this.$store.state.userData.alipay,
        re_alipay:this.$store.state.userData.alipay,
        alipayCode:this.$store.state.userData.alipay_qrcode,
        wechat:this.$store.state.userData.wechat,
        re_wechat:this.$store.state.userData.wechat,
        wechatCode:this.$store.state.userData.wechat_qrcode,
        bank:this.$store.state.userData.bankcard,
        re_bank:this.$store.state.userData.bankcard,
        bankName:this.$store.state.userData.bank_address,
        name:this.$store.state.userData.bank_realname,
      },
      rules: {
        re_alipay: [
          { validator: validateAlipay, trigger: 'blur' }
        ],
        re_wechat: [
          { validator: validateWechat, trigger: 'blur' }
        ],
        re_bank: [
          { validator: validateBank }
        ],
        name: [
          { pattern: /^([\u4e00-\u9fa5]{1,20}|[a-zA-Z\.\s]{1,20})$/, message: '请输入真实姓名1'},
          { validator: valiName, trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    //提交按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiUserSetAccount({
            alipay:this.infoForm.alipay,
            alipay_confirm:this.infoForm.re_alipay,
            alipay_qrcode:this.infoForm.alipayCode,
            wechat:this.infoForm.wechat,
            wechat_confirm:this.infoForm.re_wechat,
            wechat_qrcode:this.infoForm.wechatCode,
            bankcard:this.infoForm.bank,
            bankcard_confirm:this.infoForm.re_bank,
            bank_address:this.infoForm.bankName,
            bank_realname:this.infoForm.name,
          }).then(res=>{
            this.$store.commit('user_withdrawalAccount',this.infoForm)
            this.$message({
              center:true,
              message:"提交成功",
              type:"success"
            })
          }).catch(err=>{
            this.$message({
              center:true,
              message:err.msg,
              type:"error"
            })
          })
        } else {
          setTimeout(()=>{
            var isError= document.getElementsByClassName("is-error");
            isError[0].querySelector('input').focus();
          },100);
          console.log('error submit!!');
          return false;
        }
      });
    },
    //支付宝&&微信 收款码上传
    alipaySuccess(res, file) {
      this.alipayCode = URL.createObjectURL(file.raw);
      if(res.code === 200){
        this.infoForm.alipayCode = res.data.src
        this.$message({
          center:true,
          message:"上传成功",
          type:"success"
        })
      }else {
        this.$message({
          center:true,
          message:res.msg,
          type:"error"
        })
      }
    },
    wechatSuccess(res, file) {
      this.wechatCode = URL.createObjectURL(file.raw);
      if(res.code === 200){
        this.infoForm.wechatCode = res.data.src
        this.$message({
          center:true,
          message:"上传成功",
          type:"success"
        })
      }else {
        this.$message({
          center:true,
          message:res.msg,
          type:"error"
        })
      }
    },
    QRcodeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return isJPG && isLt5M;
    },
    getBasicInfo(){
      apiuserInfo({
        action:'setacc'
      }).then(res=>{
        this.infoForm.actual_name =res.data.cert_name
        this.infoForm.ID_num =res.data.cert_id
        this.infoForm.ph_num =res.data.telphone
        this.infoForm.alipay =res.data.alipay
        this.infoForm.wechat =res.data.wechat
        this.infoForm.bank =res.data.bankcard
        this.infoForm.bankName =res.data.bank_address
        this.infoForm.name =res.data.cert_name
        this.infoForm.wechatCode = res.data.wechat_qrcode
        this.infoForm.alipayCode = res.data.alipay_qrcode
      }).catch(err=>{
        this.$alert(err.msg, '注意', {
          confirmButtonText: '前往实名认证',
          confirmButtonClass:'withdraw_btn',
          customClass: 'withdraw_tips',
          center:true,
          callback: action => {
            this.$router.push({
              path:'/personalCenter/personalInfo/rNameAuthentication'
            })
          }
        });
      })
    }
  },
  mounted () {
    this.getBasicInfo()
  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang="less">
.withdraw_tips{
  width: 386px;
  height: 232px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 0;
  .el-message-box__header{
    padding: 38px 0 0 0;
    .el-message-box__title{
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #5B5B5B;
      line-height: 28px;
    }
  }
  .el-message-box__content{
    padding: 30px 0 0 0;
    .el-message-box__message{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      line-height: 22px;
    }
  }
  .el-message-box__btns{
    padding: 36px 0 0 0 ;
    button{
      width: 200px;
      height: 40px;
      border: none;
      background: #434ED6;
      border-radius: 28px;
      font-size: 18px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
    }
  }
}
</style>
<style lang='less' scoped>
#modify_login_pwd{
  padding: 60px 0 0 256px;
  display: flex;
  flex-direction: column;
  //align-items: center;
  /deep/.el-input{
    width: 250px;
  }
  /deep/.el-input__inner{
    width: 250px;
    height: 40px;
    background: #F9F9F9;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #8F8F8F;
  }
  /deep/.is-error .el-input__inner{
    border-color: #F56C6C;
  }
  /deep/.el-form-item__label{
    text-align: left;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    padding: 0;
  }
  .alipayCode{
    display: flex;
    flex-direction: column;
    .alipayCodeLabel{
      width: 355px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .alipayCode-uploader{
      width: 100px;
      height: 100px;
      border-radius: 4px;
      border: 1px dashed #FFBD40;
      display: flex;
      align-items: center;
      justify-content: center;
      /deep/.el-upload{
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        i{
          font-size: 24px;
          color: #909399;
        }
      }
    }
  }
  .wechatCode{
    display: flex;
    flex-direction: column;
    .wechatCodeLabel{
      width: 355px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .wechatCode-uploader{
      width: 100px;
      height: 100px;
      border-radius: 4px;
      border: 1px dashed #FFBD40;
      display: flex;
      align-items: center;
      justify-content: center;
      /deep/.el-upload{
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        i{
          font-size: 24px;
          color: #909399;
        }
      }
    }
  }
  .personal_info{
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #8F8F8F;
    padding: 0 0 0 20px;
  }
  .submit_btn{
    margin: 29px 0 85px 82px;
    width: 200px;
    height: 40px;
    line-height: 40px;
    background: #434ED6;
    border-radius: 28px;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
  }

}
</style>
